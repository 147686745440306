<template>
    <router-view v-slot="{ Component, route }">
      <!-- <transition name="fade-transform" mode="out-in"> -->
        <keep-alive>
          <component :is="Component" :key="route.path" v-if="!route.meta.noCache" />
        </keep-alive>
		<component :is="Component" :key="route.path" v-if="route.meta.noCache" />
      <!-- </transition> -->
    </router-view>
</template>
<script setup>
	// import { h } from "vue"
import { useRoute } from 'vue-router'
const route = useRoute()

// const wrapperMap = new Map()
</script>

<style lang="less" scoped>

</style>
